import React from "react"
import Seo from "../../components/seo"
import About from "../../components/Content/About"

export default props => (
  <React.Fragment>
    <Seo
      title={`Tour operador líder en Nicaragua`}
      description={`NBG ofrece servicios completos en hospedaje, traslados, tours, así como la organización de congresos y viajes de incentivos (DMC).`}
    />
    <About location={props.location} langKey={props.pageContext.langKey} />
  </React.Fragment>
)
